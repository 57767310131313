import React, { Component } from "react";
export default class Porfolio extends Component {
  render() {
    let resumeData = this.props.resumeData;
    return (
      <section id="portfolio">
        <div className="row">
          <div className="twelve columns collapsed">
            <div
              id="portfolio-wrapper"
              className="bgrid-quarters s-bgrid-thirds cf"
            >
              {resumeData.portfolio &&
                resumeData.portfolio.map((item, idx) => {
                  return (
                    <div className="columns portfolio-item" key={`${idx}`}>
                      <img
                        src={"images/portfolio/" + item.imgurl}
                        style={{
                          width: "100%",
                          height: "100%",
                          objectFit: "cover",
                        }}
                        className="pa-text-over-image"
                        alt=""
                      />
                      <button
                        class="btn"
                        onClick={() => {
                          window.location.href = item.url;
                        }}
                        style={{
                          position: "absolute",
                          top: "50%",
                          left: "50%",
                          transform: "translate(-50%, -50%)",
                          "-ms-transform": "translate(-50%, -50%)",
                          background: "transparent",
                        }}
                      >
                        Click Here
                      </button>
                    </div>
                  );
                })}
            </div>
          </div>
        </div>
      </section>
    );
  }
}
