import React, { Component } from "react";

const FORM_ENDPOINT = ""; // TODO - fill on the later step

const handleSubmit = () => {
  setTimeout(() => {}, 100);
};

export default class Porfolio extends Component {
  state = {
    submitted: false,
  };
  render() {
    if (this.state.submitted) {
      return (
        <>
          <h2>Thank you!</h2>

          <div>We'll be in touch soon.</div>
        </>
      );
    }

    return (
      <section id="contact">
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            marginTop: "50px",
          }}
        >
          <h2
            style={{
              textAlign: "center",
              fontSize: "5rem",
              fontWeight: "bold",
              marginBottom: "20px",
              color: "#fff",
            }}
          >
            Get in touch
          </h2>
          <form
            action={FORM_ENDPOINT}
            onSubmit={handleSubmit}
            method="POST"
            target="_blank"
          >
            <div>
              <input type="text" placeholder="Your name" name="name" required />
            </div>

            <div>
              <input type="email" placeholder="Email" name="email" required />
            </div>
            <div>
              <input
                type="text"
                placeholder="Subject"
                name="subject"
                required
              />
            </div>
            <div>
              <textarea placeholder="Your message" name="message" required />
            </div>

            <div>
              <button
                type="submit"
                style={{
                  alignContent: "center",
                  justifyContent: "center",
                  alignItems: "center",
                  marginLeft: "15%",
                }}
              >
                {" "}
                Send a message{" "}
              </button>
            </div>
          </form>
        </div>
      </section>
    );
  }
}
