import React, { Component } from "react";
export default class Testimonials extends Component {
  render() {
    let resumeData = this.props.resumeData;
    return (
      <section id="words">
        <div className="text-container">
          <div className="row">
            <div className="two columns header-col">
              <h1>
                <span>Words of Wisdom</span>
              </h1>
            </div>
            <div>
              <p
                className="lead center"
                style={{
                  color: "white",
                }}
              >
                Words of wisdom
              </p>
            </div>
            <div className="ten columns flex-container">
              <div className="flexslider">
                <ul className="slides">
                  {resumeData.testimonials &&
                    resumeData.testimonials.map((item, idx) => {
                      return (
                        <li key = {idx}>
                          <blockquote>
                            <p>{item.description}</p>
                            <cite>{item.name}</cite>
                          </blockquote>
                        </li>
                      );
                    })}
                </ul>
              </div>{" "}
            </div>{" "}
          </div>{" "}
        </div>{" "}
      </section>
    );
  }
}
