let resumeData = {
  imagebaseurl: "http://www.stmaryandstdemiana.org/",
  name: "Convent of St. Mary and St. Demiana",
  roleDescription: "Convent of St. Mary and St. Demiana",
  socialLinks: [
    {
      name: "linkedin",
      url: "stmaryandstdemiana.org",
      className: "fa fa-linkedin",
    },
    {
      name: "facebook",
      url: "https://www.facebook.com/stmaryandstdemiana",
      className: "fa fa-facebook",
    },
    {
      name: "instagram",
      url: "https://www.instagram.com/stmaryandstdemiana/",
      className: "fa fa-instagram",
    },
    {
      name: "twitter",
      url: "https://twitter.com/stmaryandstdem",
      className: "fa fa-twitter",
    },
    {
      name: "youtube",
      url: "https://www.youtube.com/channel/UCZQY5Z7ZQZ8ZQZ8ZQZ8ZQZ8",
      className: "fa fa-youtube",
    },
  ],
  aboutme:
    "By the grace of God and prayers of our beloved Abbot, His Grace Bishop Youssef, St. Mary Convent established its first branch and officially opened its doors in Daytona, Florida in 2006 welcoming consecrated sisters from Egypt. In May 2010 – the Coptic Orthodox Diocese of the Southern United States purchased property in Titusville, Florida – now home to St. Stephen Christian Retreat Center. The Convent had a temporary residence within the retreat center from July 2010 until December 2011. On December 13, 2011 – Feast of the Entrance of the Virgin Mary into the Temple St. Mary Convent relocated to San Antonio, Texas and began its second branch welcoming novices into the life of monasticism. Its first novice entered in January 2012 and in March of the same year, the first consecrated sister was dressed joining her sisters in service. On May 20, 2013 – Feast of Consecration of St. Demiana’s Church, St. Mary Convent officially became St. Mary & St. Demiana Convent – no longer two branches but now dedicated only for female monastics. On October 21, 2014 – St. Mary & St. Demiana Convent moved to its permanent location in Dawsonville, Georgia..",
  address: "330 Village Dr Dawsonville, GA 30534 (678) 842-3281",
  website: "https://www.stmaryconvent.org",
  portfolio: [
    {
      name: "project1",
      description: "mobileapp",
      imgurl: "pog1.jpg",
    },
    {
      name: "project1",
      description: "mobileapp",
      imgurl: "pog1.jpg",
    },
    {
      name: "project1",
      description: "mobileapp",
      imgurl: "pog1.jpg",
    },
  ],
  testimonials: [
    {
      description: "This is a sample quote",
      name: "Some churchy guy",
    },
    {
      description: "This is a sample quote",
      name: "Some churchy guy",
    },
  ],
};

export default resumeData;
